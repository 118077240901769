import React, { useState, useEffect, useRef }  from 'react'
import Link, { navigate } from 'gatsby-link'
import { ReactCompareSlider, ReactCompareSliderHandle } from 'react-compare-slider';
import { FaEthereum } from 'react-icons/fa';
import { OutboundLink } from "gatsby-plugin-google-gtag"

import Layout from '../components/layout'
import SEO from '../components/seo'
import Image from "../components/image"

const ARROWS_LEFT = ['37', 'ArrowLeft'];
const ARROWS_RIGHT = ['39', 'ArrowRight'];

const Navigation = ({ word, next, previous, checkTransactionHash, setTransactionHash, transactionHash }) => (
  <nav className="pagination is-centered my-6" role="navigation" aria-label="pagination">
    <Link to={`/${previous}/`} className="pagination-previous is-rounded button is-outlined is-dark" title="Previous Poster" style={{ visibility: !previous ? 'hidden' : 'visible' }}>&lt; {previous ? previous.split('-').join(' ') : ''}</Link>
    <Link to={`/${next}/`} className="pagination-next button is-rounded is-outlined is-dark" title="Next Poster" style={{ visibility: !next ? 'hidden' : 'visible' }}>{next ? next.split('-').join(' ') : ''} &gt;</Link>

    <div className="pagination-list">
      {word.forSale && <OutboundLink href={word.opensea} target="_blank" rel="noreferrer" className="button is-link is-rounded has-text-weight-bolder"><FaEthereum className="mr-2" /> buy on OpenSea for Ξ{word.price}</OutboundLink> }
      {!word.forSale && <button className="button is-outlined is-disabled is-rounded is-italic is-inverted" disabled>drops next week</button> } 
    </div>

    {/* <div className="field has-addons pagination-list">
      <div className="control">
        <input className="is-rounded input" type="text" placeholder="Transaction hash (0x...)" required onChange={ e => { setTransactionHash(e.target.value) }} value={ transactionHash } />
      </div>
      <div className="control">
        <a className="button is-dark is-rounded has-text-weight-bold" href="#" onClick={(e) => checkTransactionHash(e) }>
          fetch
        </a>
      </div>
    </div> */}
  </nav>
)

const useEventListener = (eventName, handler) => {
  const savedHandler = useRef();
  const element = (typeof window !== 'undefined') ? window : null;

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(
    () => {
      const isSupported = element && element.addEventListener;
      if (!isSupported) return;

      const eventListener = event => savedHandler.current(event);
      element.addEventListener(eventName, eventListener);

      return () => {
        element.removeEventListener(eventName, eventListener);
      };
    },
    [eventName, element]
  );
};

const WordPage = ({ pageContext: { previous, next }, data: { word } }) => {
  const [showTxModal, setShowTxModal] = useState(false);
  const [transactionHash, setTransactionHash] = useState('')

  // const customMeta = [{
  //   name: `twitter:image`,
  //   content: `https://www.mypixelplanet.com${image.childImageSharp.fixed.src}`,
  // }];

  const checkTransactionHash = (e) => {
    e.preventDefault();
    alert('That transaction hash was not found');
  }

  const goPrevNextHandler = ({ key }) => {
    if (previous && ARROWS_LEFT.includes(String(key))) {
      navigate(`/${previous}/`)
    } else if (next && ARROWS_RIGHT.includes(String(key))) {
      navigate(`/${next}/`)
    }
  }

  useEventListener('keydown', goPrevNextHandler);

  return (
    <Layout title={word.word}>
        <SEO title={word.word} description={``}  />

        <Navigation word={word} next={next} previous={previous} transactionHash={transactionHash} checkTransactionHash={checkTransactionHash} setTransactionHash={setTransactionHash} />

        <div className="frame is-centered">
          <div className="poster">
            <h2 className="title has-text-black is-size-05 is-size-2-mobile">{word.word}.</h2>
            
            <figure className="image" style={{ width: "100%" }}>
              <ReactCompareSlider
                itemOne={<Image fileName={word.images.colorised.min} alt={word.word} />}
                itemTwo={<Image fileName={word.images.bw.min} alt={word.word} />}
                handle={
                  <ReactCompareSliderHandle
                    buttonStyle={{
                      backgroundColor: '#222',
                      color: '#eee',
                      border: 0,
                    }}
                    linesStyle={{ backgroundColor: '#222', width: '5px' }}
                  />
                }
              />
            </figure>

            <div className="my-6 level">
              <div className="level-left" style={{ display: "block" }}>
                <div className="line level-item mb-1" style={{ height: "0.2rem", width: "5rem" }}></div>
                <p className="level-item">
                  thedepicted.com/{word.id}
                </p>
              </div>

              <div className="level-right mt-2">
                <div className="transaction-hash" onClick={(e) => setShowTxModal(!showTxModal)}>
                  <span>0xSAMPLE</span>
                  <span className="ping"></span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={`modal ${showTxModal ? "is-active" : ""}`}>
          <div className="modal-background"></div>
          <div className="modal-content has-background-white">
            <div className="content p-6">The transaction hash (<em>0xSAMPLE</em> in this sample) is used as a seed at the time of minting. This means each poster is dynamically created, unique to you, and can be algorithmically re-created in the event of loss.</div>
          </div>
          <button className="modal-close is-large" aria-label="close" onClick={(e) => setShowTxModal(false)}></button>
        </div>

        <Navigation word={word} next={next} previous={previous} transactionHash={transactionHash} checkTransactionHash={checkTransactionHash} setTransactionHash={setTransactionHash} />
    </Layout>
  )
}
  
export default WordPage

export const query = graphql`
  query Word($id: String!) {
    word(id: { eq: $id }) {
      id
      word
      price
      forSale
      opensea
      images {
        colorised {
          full, min
        }
        bw {
          full, min
        }
      }
    }
  }
`